<template>
  <section>
    <OnlineReportageFilter />
    <Preloader v-if="callingAPI" />
    <OnlineReportageTable />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import OnlineReportageFilter from '@/components/onlineReportage/vlm/OnlineReportageFilterVlm'
import OnlineReportageTable from '@/components/table/OnlineReportageTableVlm'

export default {
  name: 'OnlineReportageListViewVlm',
  computed: {
    ...mapState([
      'callingAPI'
    ])
  },
  components: {
    OnlineReportageTable,
    Preloader,
    OnlineReportageFilter
  }
}
</script>
