<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="onlineReportages"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    store-name="onlineReportageVlm"
    table-css-class="online-reportage-table-vlm"
  >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="getSiteName(item.siteId)"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="item.title"
      />
      <CreatedByAndAtColumn
        :created-at="item.createdAt"
        :created-by="item.createdById"
      />
      <CreatedByAndAtColumn
        :title-header="tableHeaders[5].name"
        :subtitle-header="tableHeaders[5].subHeader"
        :created-at="item.modifiedAt"
        :created-by="item.modifiedById"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import CreatedByAndAtColumn from '@/components/table/columns/CreatedByAndAtColumn'

export default {
  name: 'OnlineReportageTableVlm',
  components: {
    CreatedByAndAtColumn,
    TitleColumn,
    AbstractDataTable
  },
  computed: {
    tableHeaders () {
      return [
        { name: this.$t('onlineReportageVlm.list.id') },
        { name: this.$t('onlineReportageVlm.list.server') },
        { name: this.$t('onlineReportageVlm.list.title') },
        {
          name: this.$t('system.created_at'),
          subHeader: this.$t('system.created_by')
        },
        {
          name: this.$t('system.modified_at'),
          subHeader: this.$t('system.modified_by')
        },
        { name: this.$t('actions') }
      ]
    },
    onlineReportages () {
      return this.$store.getters['onlineReportageVlm/list']
    },
    totalCount () {
      return this.$store.getters['onlineReportageVlm/totalCount']
    },
    currentPage () {
      return this.$store.getters['onlineReportageVlm/page']
    }
  },
  methods: {
    getSiteName (siteId) {
      const site = this.$store.getters['site/siteById'](siteId)
      if (site) {
        return site.title
      }
      return siteId
    },
    getOnlineReportages () {
      this.$store.dispatch('onlineReportageVlm/fetch')
    },
    onPageChange (page) {
      this.$store.commit('onlineReportageVlm/setPage', page)
      this.getOnlineReportages()
    }
  },
  mounted () {
    this.getOnlineReportages()
  }
}
</script>
<style lang="scss">
.online-reportage-table-vlm {
  &__thead,
  &__tr {
    grid-template-columns: rem(80px) repeat(auto-fit, minmax(100px, 1fr));
  }
}
</style>
